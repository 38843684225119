body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerSearch{
  
  display: -ms-flexbox;
  display: flex;
  margin:0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: 'Poppins', sans-serif;
  background: url("assets/Searchs_006.png")!important;
  background-size: cover;
  background-position: center center;
  padding: 15px;

}


.s006 {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: 'Poppins', sans-serif;
  background: url("assets/Searchs_006.png");
  background-size: cover;
  background-position: center center;
  padding: 15px;
}



form {
  width: 100%;
  max-width: 790px;
}

form legend {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  margin-bottom: 59px;
}

form .inner-form {
  width: 100%;
  margin-bottom: 17px;
}

form .inner-form .input-field {
  height: 70px;
  width: 100%;
  position: relative;
}

form .inner-form .input-field input {
  height: 100%;
  width: 100%;
  background: transparent;
  border: 0;
  background: #fff;
  display: block;
  width: 100%;
  padding: 10px 32px 10px 70px;
  font-size: 18px;
  color: #666;
  border-radius: 34px;
}

form .inner-form .input-field input.placeholder {
  color: #ccc;
  font-size: 18px;
}

form .inner-form .input-field input:-moz-placeholder {
  color: #ccc;
  font-size: 18px;
}

form .inner-form .input-field input::-webkit-input-placeholder {
  color: #ccc;
  font-size: 18px;
}

form .inner-form .input-field input:hover, form .inner-form .input-field input:focus {
  box-shadow: none;
  outline: 0;
}

form .inner-form .input-field .btn-search {
  width: 70px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  left: 0;
  height: 100%;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  align-items: center;
}

form .inner-form .input-field .btn-search svg {
  fill: #ccc;
  width: 30px;
  height: 30px;
  transition: all .2s ease-out, color .2s ease-out;
}

form .inner-form .input-field .btn-search:hover, form .inner-form .input-field .btn-search:focus {
  outline: 0;
  box-shadow: none;
}

form .inner-form .input-field .btn-search:hover svg, form .inner-form .input-field .btn-search:focus svg {
  fill: #666;
}

form .suggestion-wrap {
  padding: 0 15px;
}

form .suggestion-wrap span {
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  display: inline-block;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 15px;
  line-height: 32px;
  color: #fff;
  border-radius: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  form .inner-form .input-field {
    margin-bottom: 20px;
    height: 50px;
  }
  form .inner-form .input-field input {
    padding-left: 55px;
    font-size: 16px;
  }
  form .inner-form .input-field .btn-search svg {
    width: 26px;
    height: 26px;
  }
}
